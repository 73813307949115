<template>
  <div id="app">
    <slide-nav :cityList="cityList"></slide-nav>
    <transition name="fade">
      <my-header class="myheader" ref='myHeader' v-show="isShow"></my-header>
    </transition>
    <keep-alive>
      <router-view/>
    </keep-alive>
    <el-image :src="bottomImg" class="bottomImg"></el-image>
    <a class="beian-link" href="https://beian.miit.gov.cn" target="_blank">湘ICP备18005727号</a>
    <div class="backtop" v-show="viHeight >= 200" @click="scrollToTop">
      <i class="el-icon-caret-top"></i>
    </div>
  </div>
</template>

<script>
import SlideNav from '@/views/SlideNav'
import MyHeader from '@/components/MyHeader/MyHeader'
import { getCityList } from '@/request/api'
import { get } from '@/request/http'
export default {
  components: {
    SlideNav,
    MyHeader
  },
  data () {
    return {
      cityList: {},
      isShow: true,
      bottomImg: require('@/assets/profileBottomBanner.png'),
      viHeight: 0
    }
  },
  mounted () {
    this.getCityList()
    window.addEventListener('scroll', this.hideHeader, true)
  },
  methods: {
    getCityList () {
      get(getCityList).then(res => {
        if (res.retCode === 200) {
          this.cityList = res
          this.$root.Bus.$emit('cityList', res)
        }
      })
    },
    hideHeader () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollTop)
      this.viHeight = scrollTop
      if (scrollTop > 180) { // 从上往下滑动, 显示顶部
        this.isShow = false
      } else { // 从下往上滑动, 影藏顶部
        this.isShow = true
      }
    },
    scrollToTop () {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 100
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    }
  }
}
</script>

<style lang="stylus">
@import './assets/font/font.css'
#app
  color: #3a3a3a
  font-size: 16px
  font-family: 'PingFang'
  overflow: auto
  .fade-enter-active, .fade-leave-active
    transition: opacity .5s
  .fade-enter, .fade-leave-to
    opacity: 0
  .myheader
    position: fixed
    top: 0
    left: 0
    right: 0
  .beian-link
    display: block;
    text-align: center;
    font-size: 12px;
    text-decoration: none;
    padding: 10px 0;
  .backtop
    position: fixed
    right: 20px
    bottom: 40px
    background-color: #fff
    width: 40px
    height: 40px
    border-radius: 50%
    color: #409eff
    display: flex
    align-items: center
    justify-content: center
    font-size: 20px
    box-shadow: 0 0 6px rgba(0,0,0,.12)
    cursor: pointer
    z-index: 5
    opacity: .7
</style>
