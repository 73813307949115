<template>
  <div class="profile">
    <div class="profile-part1">
      <el-image :src="cityList.titleImageUrl" style="width: 100%;"></el-image>
    </div>
    <div class="profile-part2">
      <div class="part2-main" v-for="item in cityList.param" :key="item.id">
        <el-image class="main-img" :src="item.cityImageUrl"></el-image>
        <el-image class="main-more" :src="profileReadMore" @click="readMore(item.id, item.cityName)" ref="readMore" lazy></el-image>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Profile',
  data () {
    return {
      profileReadMore: require('./images/profileReadMore.png'),
      cityList: {}
    }
  },
  mounted () {
    this.$root.Bus.$on('cityList', e => {
      // console.log(e)
      this.cityList = e
    })
  },
  methods: {
    readMore (id, name) {
      // console.log(id)
      this.$router.push({
        path: '/cities',
        query: {
          name: name,
          id: id
        }
      })
      this.$root.Bus.$emit('navActive', name)
    }
  }
}
</script>

<style scoped lang="stylus">
.profile
  margin-top: 62px
  .profile-part1
    text-align: center
  .profile-part2
    .part2-main
      overflow: hidden
      position: relative
      .main-img
        width: 100%
      .main-more
        width: 124px
        height: 35px
        border-radius: 100px 0 0 100px
        position: absolute
        right: 0
        bottom: 80px
</style>
