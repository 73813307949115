<template>
  <div>
    <div class="title">入驻企业</div>
    <div class="main">
      <div class="main-item" v-for="item in companyList" :key="item.id">
        <div class="item-content">
          <div class="imgWrapper">
            <el-image :src="item.logoUrl" class="logo"></el-image>
          </div>
          <div class="title">{{item.companyName}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from '@/request/http'
import { getCompanyList } from '@/request/api'
export default {
  name: 'Enterprise',
  data () {
    return {
      companyList: []
    }
  },
  mounted () {
    this.getCompanyList()
  },
  methods: {
    getCompanyList () {
      get(getCompanyList).then(res => {
        if (res.retCode === 200) {
          // console.log(res)
          this.companyList = res.param
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
.title
  text-align: center
  line-height: 80px
  font-weight: 600
  margin-top: 62px
.main
  box-sizing: border-box
  display: flex
  justify-content: space-between
  flex-flow: row wrap
  .main-item
    width: 50%
    margin-bottom: 20px
    .item-content
      width: 80%
      margin: 0 auto
      .imgWrapper
        width: 100%
        .logo
          width: 100%
      .title
        text-align: center
        font-size: 16px
        color: #333
        margin-top: 5px
</style>
