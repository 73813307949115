import { render, staticRenderFns } from "./Cultural.vue?vue&type=template&id=ab1b163e&scoped=true&"
import script from "./Cultural.vue?vue&type=script&lang=js&"
export * from "./Cultural.vue?vue&type=script&lang=js&"
import style0 from "./Cultural.vue?vue&type=style&index=0&id=ab1b163e&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab1b163e",
  null
  
)

export default component.exports