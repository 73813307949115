<template>
  <div class="info">
    <div class="info-tabs">
      <div class="info-item"  @click="handleItemClick('news')" :class="{ tabActive : currentTab === 'news' }">新闻动态</div>
      <div class="info-item"  @click="handleItemClick('activity')" :class="{ tabActive : currentTab === 'activity' }">活动</div>
      <div class="info-item"  @click="handleItemClick('jobs')" :class="{ tabActive : currentTab === 'jobs' }">工作机会</div>
    </div>
    <div class="info-content" v-if="currentTab === 'news'">
      <news-info :list="newsList"></news-info>
    </div>
    <div class="info-content" v-if="currentTab === 'activity'">
      <activity-info :list="activityList"></activity-info>
    </div>
    <div class="info-content" v-if="currentTab === 'jobs'">
      <job-info></job-info>
    </div>
  </div>
</template>

<script>
import NewsInfo from './NewsInfo'
import ActivityInfo from './ActivityInfo'
import JobInfo from './JobInfo'
import { getNewsList } from '@/request/api'
import { get } from '@/request/http'
export default {
  name: 'InformationCenter',
  components: {
    NewsInfo,
    ActivityInfo,
    JobInfo
  },
  data () {
    return {
      currentTab: 'news',
      newsList: [],
      activityList: []
    }
  },
  mounted () {
    this.getNewsList()
    this.getActivityList()
  },
  methods: {
    handleItemClick (index) {
      this.currentTab = index
      this.$router.push({
        path: '/info',
        name: 'InformationCenter',
        query: {
          name: index
        }
      })
      this.$root.Bus.$emit('navActive', index)
    },
    getNewsList () {
      get(getNewsList + '/id/0').then(res => {
        if (res.retCode === 200) {
          var newsList = res.param
          for (var i = 0; i < newsList.length; i++) {
            var imageList = []
            imageList.push(newsList[i].imageUrl1)
            imageList.push(newsList[i].imageUrl2)
            imageList.push(newsList[i].imageUrl3)
            imageList.push(newsList[i].imageUrl4)
            imageList.push(newsList[i].imageUrl5)
            imageList.push(newsList[i].imageUrl6)
            imageList.push(newsList[i].imageUrl7)
            imageList.push(newsList[i].imageUrl8)
            imageList.push(newsList[i].imageUrl9)
            newsList[i].imageList = imageList

            // 获取当前时间, 和数据对比, 24小时为86400秒
            var nowDate = Math.round(new Date().getTime() / 1000)
            if (nowDate - newsList[i].updatedTime > 86400) { // 如果是24小时以前, 显示24小时以前
              newsList[i].publicTime = this.$moment(newsList[i].updatedTime * 1000).format('YYYY年MM月DD日')
            } else { // 如果是24小时以内, 显示多少个小时以前
              newsList[i].publicTime = Math.round(newsList[i].updatedTime / nowDate) + '小时前'
            }
          }
          this.newsList = newsList
        }
      })
    },
    getActivityList () {
      get(getNewsList + '/id/1').then(res => {
        // console.log('活动:', res)
        if (res.retCode === 200) {
          var activityList = res.param
          for (var i = 0; i < activityList.length; i++) {
            var imageList = []
            imageList.push(activityList[i].imageUrl1)
            imageList.push(activityList[i].imageUrl2)
            imageList.push(activityList[i].imageUrl3)
            imageList.push(activityList[i].imageUrl4)
            imageList.push(activityList[i].imageUrl5)
            imageList.push(activityList[i].imageUrl6)
            imageList.push(activityList[i].imageUrl7)
            imageList.push(activityList[i].imageUrl8)
            imageList.push(activityList[i].imageUrl9)
            activityList[i].imageList = imageList

            // 获取当前时间, 和数据对比, 24小时为86400秒
            var nowDate = Math.round(new Date().getTime() / 1000)
            if (nowDate - activityList[i].updatedTime > 86400) { // 如果是24小时以前, 显示24小时以前
              activityList[i].publicTime = this.$moment(activityList[i].updatedTime * 1000).format('YYYY-MM-DD')
            } else { // 如果是24小时以内, 显示多少个小时以前
              activityList[i].publicTime = Math.round(activityList[i].updatedTime / nowDate) + '小时前'
            }
          }
          this.activityList = activityList
        }
      })
    }
  },
  watch: {
    $route () { // 监听页面路由参数
      this.currentTab = this.$route.query.name
    }
  }
}
</script>

<style scoped lang="stylus">
.info
  margin-top: 62px
  box-sizing: border-box
  padding: 16px 24px 30px
  .info-tabs
    display: flex
    justify-content: center
    .info-item
      width: 120px
      box-sizing: border-box
      padding: 8px 0px
      font-size: 14px
      background: #6F7074
      color: #ffffff
      border-radius: 20px
      opacity: .8
      text-align: center
      &.info-item:nth-child(2)
        margin: 0 10px
      &.tabActive
        background: #ff6464
  .info-content
    margin-top: 28px
</style>
