
const url = 'https://admin.cmvstv.com/cmvs/getjson/' // 远程测试环境

module.exports = {
  getCityList: url + 'getCityList', // 获取城市列表, 导航城市/首页城市
  getParkList: url + 'getParkList/id/', // 获取城市的园区
  getCompanyList: url + 'getCompanyList', // 获取企业列表
  getCompInfo: url + 'getCompInfo', // 获取企业简介 文化氛围
  getNewsList: url + 'getNewsList', // 获取新闻动态及活动 参数0 新闻, 参数1 活动
  setJob: url + 'setJobInfo', // 提交求职 get请求
  getCompServices: url + 'getCompServices'
}
