<template>
  <div class="slide-bar-container">
    <el-drawer :visible.sync="showMenu" direction="ltr" size="290px" :wrapperClosable="true" :withHeader="false" @close="closeDrawer">
      <div class="slideNav">
        <div class="logoContainer">
          <el-image :src="src" class="logoImage"></el-image>
        </div>
        <el-menu
          :default-active="navActive"
          :unique-opened="true"
          background-color="#ffffff"
          text-color="#333333"
          active-text-color="#FF3D3D"
          @select="menuIndex">
          <el-menu-item index="profile" style="font-size: 16px;">
            <router-link tag="div" :to="{ path: '/profile', name: 'Profile'}">首页</router-link>
          </el-menu-item>
          <el-submenu index="1">
            <template slot="title">
              <span>关于我们</span>
            </template>
            <el-menu-item index="companyProfile">
              <router-link tag="div" :to="{ path: '/aboutus', name: 'AboutUs', query: { name: 'companyProfile' } }">企业简介</router-link>
            </el-menu-item>
            <el-menu-item index="cultural">
              <router-link tag="div" :to="{ path: '/aboutus', name: 'AboutUs', query: { name: 'cultural' } }">文化氛围</router-link>
            </el-menu-item>
            <el-menu-item index="works">
              <router-link tag="div" :to="{ path: '/aboutus', name: 'AboutUs', query: { name: 'works' } }">作品展示</router-link>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <span>城市分布</span>
            </template>
            <el-menu-item v-for="item in cityList.param" :key="item.id" :index="item.cityName">
              <router-link tag="div" :to="{ path: '/cities', name: 'UrbanDistribution', query: { name: item.cityName, id: item.id } }">{{item.cityName}}</router-link>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <span>
                服务企业
              </span>
            </template>
            <el-menu-item index="service">
              <router-link tag="div" :to="{ path: '/service', name: 'Services' }">服务</router-link>
            </el-menu-item>
            <el-menu-item index="enterprises">
              <router-link tag="div" :to="{ path: '/enterprises', name: 'Enterprise' }">企业</router-link>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <span>资讯中心</span>
            </template>
            <el-menu-item index="news">
              <router-link tag="div" :to="{ path: '/info', name: 'InformationCenter', query: { name: 'news' } }">新闻动态</router-link>
            </el-menu-item>
            <el-menu-item index="activity">
              <router-link tag="div" :to="{ path: '/info', name: 'InformationCenter', query: { name: 'activity' } }">活动</router-link>
            </el-menu-item>
            <el-menu-item index="jobs">
              <router-link tag="div" :to="{ path: '/info', name: 'InformationCenter', query: { name: 'jobs' } }">工作机会</router-link>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </el-drawer>
  </div>
</template>

<script>

export default {
  name: 'SlideNav',
  props: {
    cityList: Object
  },
  data () {
    return {
      src: require('@/assets/logo.png'),
      showMenu: false,
      navActive: 'profile'
    }
  },
  mounted () {
    this.$root.Bus.$on('showNavBar', e => {
      this.showMenu = e
    })
    this.$root.Bus.$on('navActive', e => {
      this.navActive = e
    })
  },
  methods: {
    preventEvents () {
      // console.log('阻止事件冒泡')
    },
    menuIndex (item) {
      // console.log(item)
      this.navActive = item
      this.showMenu = false
      this.$root.Bus.$emit('menuActive', false)
    },
    hideMenu () {
      this.showMenu = false
      this.$root.Bus.$emit('menuActive', false)
    },
    closeDrawer () {
      this.$root.Bus.$emit('menuActive', false)
    }
  }
}
</script>

<style scoped lang="stylus">
  .el-drawer__wrapper
    top: 62px
  .el-drawer >>> .el-drawer__header
    margin-bottom: 0
  .slideNav
    width: 290px
    height: 100vh
    background: #ffffff
    box-sizing: border-box
    overflow: hidden
    .logoContainer
      box-sizing: border-box
      padding-top: 24px
      text-align: center
      .el-image
        width: 42px
      .name
        font-size: 16px
        font-weight: 600
        margin-top: 18px
    .el-menu
      width: 100%
      height: 100%
      overflow: scroll
      box-sizing: border-box
      padding: 0 20px
    .el-menu >>> .el-submenu__title
      font-size: 16px
    .el-menu >>> .el-submenu__title > i
      color: #000000
      font-weight: 600
</style>
