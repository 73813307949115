<template>
  <div class="main">
    <div class="main-item" v-for="item in list" :key="item.id">
      <div class="item-left">
        <div class="avatar-wrap">
          <el-image :src="item.headImageUrl" alt="" class="avatar"></el-image>
        </div>
      </div>
      <div class="item-right">
        <div class="title">{{ item.title }}</div>
        <div class="content">{{ item.content }}</div>
        <div class="mediaWrapper">
          <template v-if="item.videoUrl === '' || item.videoUrl === null">
            <div style="width: 33.33%;float: left;box-sizing: border-box;padding: 5px"  v-for="(subItem, index) in item.imageList" :key="index" >
              <el-image class="mediaItem" ref="mediaItem" v-if="subItem" :src="subItem" lazy :preview-src-list="item.imageList" :fit="fit"></el-image>
            </div>
          </template>
          <template v-else>
            <video class="video" v-if="item.videoUrl" :src="item.videoUrl"></video>
          </template>
        </div>
        <div class="address">{{ item.address }}</div>
        <div class="o_content">
          <div class="o_date">{{ item.publicTime }}</div>
          <div class="o_share"
            v-clipboard:copy="'https://devweb.csljsz.com/cmvs/#/profile'"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError">分享</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsInfo',
  data () {
    return {
      imgList: [],
      fit: 'cover'
    }
  },
  props: {
    list: Array
  },
  methods: {
    onCopy () {
      this.$message.success('复制链接成功')
    },
    onError () {
      this.$message.error('复制链接失败')
    }
  }
}
</script>

<style scoped lang="stylus">
.main
  margin-top: 42px
  .main-item
    display: flex
    border-bottom: 1px solid #d8d8d8
    padding-bottom: 20px
    margin-bottom: 20px
    .item-left
      flex: 2
      margin-right: 10px
      .avatar-wrap
        width: 40px
        .avatar
          display: block
          width: 100%
          background: red
    .item-right
      flex: 10
      .title
        color: #272727
        font-size: 20px
        margin-bottom: 5px
        font-weight: 600
        line-height: 25px
      .content
        font-size: 14px
        color: #191919
        line-height: 22px
        font-weight: 400
        margin-top: 5px
      .mediaWrapper
        margin-top: 10px
        &.mediaWrapper:after
          display: block
          content: ''
          clear: both
        .mediaItem
          width: 100%
        .video
          width: 100%
      .address
        font-size: 14px
        color: #191919
        margin-top: 10px
        font-weight: 500
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      .o_content
        display: flex
        justify-content: space-between
        margin-top: 5px
        .o_date
          color: #cccccc
          font-size: 14px
          line-height: 23px
        .o_share
          font-size: 12px
          line-height: 17px
          padding: 3px
          border-radius: 2px
          background: #fb7372
          color: #fff
    .item-right >>> .el-image-viewer__mask
      opacity: .6
</style>
