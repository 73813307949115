<template>
  <div class="mainBody">
    <iframe id="myiframe" class="iframeContainer" src="https://sadmin.cmvstv.com/show.html" frameborder="0" scrolling="auto"></iframe>
  </div>
</template>

<script>
export default {
  name: 'ShowWorks',
  mounted () {
    var iframe = document.getElementById('myiframe')
    var bHeight = iframe.contentWindow.document.body.scrollHeight
    var dHeight = iframe.contentWindow.document.documentElement.scrollHeight
    var height = Math.max(bHeight, dHeight)
    iframe.height = height
  },
  methods: {
    changeFrameHeight () {
      var ifm = this.$refs.myiframe
      console.log(ifm)
      ifm.height = document.documentElement.clientHeight
    }
  }
}
</script>

<style scoped lang="stylus">
.mainBody
  padding-bottom: 20px
  .iframeContainer
    width: 100%
    min-height: 100vh
</style>
