<template>
  <div class="about-us">

    <div class="aboutus-tabs">
      <div class="tab-item"  @click="handleItemClick('companyProfile')" :class="{ tabActive : currentTab === 'companyProfile' }">企业简介</div>
      <div class="tab-item"  @click="handleItemClick('cultural')" :class="{ tabActive : currentTab === 'cultural' }">文化氛围</div>
      <div class="tab-item" @click="handleItemClick('works')" :class="{ tabActive : currentTab === 'works' }">作品展示</div>
    </div>

    <div class="aboutus-content" v-if="currentTab === 'companyProfile'">
      <company-profile :cData="companyInfo"></company-profile>
    </div>
    <div class="aboutus-content" v-if="currentTab === 'cultural'">
      <cultural :cData="culturalInfo"></cultural>
    </div>
    <div class="aboutus-content" v-if="currentTab === 'works'">
      <show-works></show-works>
    </div>
  </div>
</template>

<script>
import CompanyProfile from './CompanyProfile'
import Cultural from './Cultural'
import ShowWorks from './ShowWorks'
import { getCompInfo } from '@/request/api'
import { get } from '@/request/http'
export default {
  name: 'AboutUs',
  components: {
    CompanyProfile,
    Cultural,
    ShowWorks
  },
  data () {
    return {
      currentTab: 'companyProfile',
      companyInfo: '',
      culturalInfo: ''
    }
  },
  mounted () {
    const name = this.$route.query.name
    if (name) {
      this.currentTab = name
    }
    this.getCompInfo()
  },
  methods: {
    handleItemClick (index) {
      this.$router.push({
        path: '/aboutus',
        query: {
          name: index
        }
      })
      this.currentTab = index
      this.$root.Bus.$emit('navActive', index)
    },
    getCompInfo () {
      get(getCompInfo).then(res => {
        // console.log(res)
        if (res.retCode === 200) {
          this.companyInfo = res.param.companyInfo
          this.culturalInfo = res.param.cultural
        }
      })
    }
  },
  watch: {
    $route () { // 监听页面路由参数
      this.currentTab = this.$route.query.name
    }
  }
}
</script>

<style scoped lang="stylus">
.about-us
  margin-top: 62px
  .aboutus-tabs
    display: flex
    justify-content: center
    box-sizing: border-box
    padding: 20px 0
    .tab-item
      padding: 6px 16px
      font-size: 14px
      margin: 0 10px
      background: #6F7074
      color: #ffffff
      border-radius: 20px
      opacity: .8
      &.tabActive
        background: #ff6464
</style>
