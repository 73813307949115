<template>
  <div class="main-body">
    <div class="header-title">
      <el-image :src="logo" @click="returnToIndex"></el-image>
      <!-- <el-image :src="menu" class="showNav" @click="showNav"></el-image> -->
      <section class="mod model-1">
        <div class="menu" @click="changeMenuStatus">
          <div class="bar" :class="{ barTop : menuActive === false, 'barTopActive' : menuActive === true}"></div>
          <div class="bar" :class="{ barMiddle : menuActive === false, 'barMiddleActive' : menuActive === true}"></div>
          <div class="bar" :class="{ barBottom : menuActive === false, 'barBottomActive' : menuActive === true}"></div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyHeader',
  data () {
    return {
      logo: require('./images/LOGO.png'),
      menu: require('./images/menu.png'),
      menuActive: false
    }
  },
  mounted () {
    this.$root.Bus.$on('menuActive', e => {
      this.menuActive = e
    })
  },
  methods: {
    showNav () {
      this.$root.Bus.$emit('showNavBar', true)
    },
    returnToIndex () {
      this.$router.push({
        path: '/profile'
      })
      this.$root.Bus.$emit('navActive', 'profile')
    },
    changeMenuStatus () {
      this.menuActive = !this.menuActive
      this.$root.Bus.$emit('showNavBar', this.menuActive)
    }
  }
}
</script>

<style scoped lang="stylus">
.main-body
  padding: 16px 33px
  position: relative
  background: #fff
  z-index: 1
  border-bottom: 1px solid #efefef
  opacity: 0.9
  .header-title
    font-size: 16px
    color: #333
    .el-image
      display: inline-block
      width: 75px
      height: 27px
    .showNav
      float: right
      width: 20px
      height: 14px
      margin-top: 7px
    .mod
      float: right
      position: relative
      top: 5px
      right: 20px
      .bar
        height: 2px
        width: 24px
        display: block
        margin: 2px 0
        position: relative
        background-color: #000
        -moz-border-radius: 10px
        -webkit-border-radius: 10px
        border-radius: 10px
        -moz-transition: 0.4s
        -o-transition: 0.4s
        -webkit-transition: 0.4s
        transition: 0.4s
        position: absolute
      .barTop
        top: 0px
        -moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s
        -o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s
        -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out
        -webkit-transition-delay: 0.3s, 0.1s
        transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s
        -moz-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01)
        -webkit-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01)
        animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01)
      .barTopActive
        top: 5px
        -moz-transform: rotate(45deg)
        -ms-transform: rotate(45deg)
        -webkit-transform: rotate(45deg)
        transform: rotate(45deg)
        -moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s
        -o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s
        -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out
        -webkit-transition-delay: 0.1s, 0.5s
        transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s
      .barMiddle
        top: 5px
        -moz-transition: 0.3s ease 0.3s
        -o-transition: 0.3s ease 0.3s
        -webkit-transition: 0.3s ease
        -webkit-transition-delay: 0.3s
        transition: 0.3s ease 0.3s
        -moz-animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01)
        -webkit-animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01)
        animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01)
      .barMiddleActive
        opacity: 0
      .barBottom
        top: 10px
        -moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s
        -o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s
        -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out
        -webkit-transition-delay: 0.3s, 0.1s
        transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s
        -moz-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01)
        -webkit-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01)
        animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01)
      .barBottomActive
        top: 5px
        -moz-transform: rotate(-45deg)
        -ms-transform: rotate(-45deg)
        -webkit-transform: rotate(-45deg)
        transform: rotate(-45deg)
        -moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s
        -o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s
        -webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out
        -webkit-transition-delay: 0.1s, 0.5s
        transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s
      @-moz-keyframes
        0%
          -moz-transform: translateY(0px) rotate(0)
          transform: translateY(0px) rotate(0)
        50%
          -moz-transform: translateY(-15px) rotate(0)
          transform: translateY(-15px) rotate(0)
        100%
          -moz-transform: translateY(-15px) rotate(-45deg)
          transform: translateY(-15px) rotate(-45deg)
      @-webkit-keyframes
        0%
          -webkit-transform: translateY(0px) rotate(0)
          transform: translateY(0px) rotate(0)
        50%
          -webkit-transform: translateY(-15px) rotate(0)
          transform: translateY(-15px) rotate(0)
        100%
          -webkit-transform: translateY(-15px) rotate(-45deg)
          transform: translateY(-15px) rotate(-45deg)
      @keyframes
        0%
          -moz-transform: translateY(0px) rotate(0)
          -ms-transform: translateY(0px) rotate(0)
          -webkit-transform: translateY(0px) rotate(0)
          transform: translateY(0px) rotate(0)
        50%
          -moz-transform: translateY(-15px) rotate(0)
          -ms-transform: translateY(-15px) rotate(0)
          -webkit-transform: translateY(-15px) rotate(0)
          transform: translateY(-15px) rotate(0)
        100%
          -moz-transform: translateY(-15px) rotate(-45deg)
          -ms-transform: translateY(-15px) rotate(-45deg)
          -webkit-transform: translateY(-15px) rotate(-45deg)
          transform: translateY(-15px) rotate(-45deg)
</style>
