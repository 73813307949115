import Vue from 'vue'
import VueRouter from 'vue-router'
import Profile from '@/components/Profile/Profile'
import AboutUs from '@/components/AboutUs/AboutUs'
import UrbanDistribution from '@/components/UrbanDistribution/UrbanDistribution'
import Services from '@/components/ServiceEnterprises/Services'
import Enterprises from '@/components/ServiceEnterprises/Enterprise'
import InformationCenter from '@/components/InformationCenter/InformationCenter'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => {
    return err
  })
}

const routes = [{
  path: '/',
  redirect: '/profile'
}, {
  path: '/profile',
  component: Profile,
  name: 'Profile'
}, {
  path: '/aboutus',
  component: AboutUs,
  name: 'AboutUs'
}, {
  path: '/cities',
  component: UrbanDistribution,
  name: 'UrbanDistribution'
}, {
  path: '/service',
  component: Services,
  name: 'Services'
}, {
  path: '/enterprises',
  component: Enterprises,
  name: 'Enterprise'
}, {
  path: '/info',
  component: InformationCenter,
  name: 'InformationCenter'
}]

const router = new VueRouter({
  routes
})

export default router
