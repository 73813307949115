<template>
  <div class="cities">
    <el-image :src="titleImg" class="titleImg" ref="getHeight"></el-image>
    <div class="images-box" v-if="imageList.length">
      <div class="content">
        <el-image v-for="item in imageList" :key="item.id" :src="item.parkImageUrl" class="image img-hook"></el-image>
      </div>
    </div>
  </div>
</template>

<script>
import { getParkList } from '@/request/api'
import { get } from '@/request/http'
export default {
  name: 'UrbanDistribution',
  data () {
    return {
      name: '',
      id: 0,
      titleImg: '',
      imageList: []
    }
  },
  mounted () {
    var query = this.$route.query
    this.name = query.name
    this.id = query.id
    this.getCityData()
  },
  watch: {
    $route () { // 监听页面路由参数
      // console.log(this.$route.query)
      var query = this.$route.query
      this.name = query.name
      this.id = query.id
      this.getCityData()
    }
  },
  methods: {
    getCityData () {
      get(getParkList + this.id).then(res => {
        // console.log('获取城市数据', res)
        if (res.retCode === 200) {
          this.titleImg = res.cityNameBigImageUrl
          this.imageList = res.param
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
.cities
  margin-top: 62px
  .titleImg
    display: block
    margin: 0 auto
    width: 100%
  .images-box
    box-sizing: border-box
    padding: 20px 11px
    overflow: auto
    .image
      width: 100%
</style>
