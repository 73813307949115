<template>
  <div class="jobInfo">
    <el-form ref="form" :model="form">
      <el-form-item label="姓名">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="电话">
        <el-input type="number" v-model="form.phone" maxlength="11"></el-input>
      </el-form-item>
      <el-form-item label="意向职位" class="formItem">
        <el-cascader
          v-model="form.position"
          clearable
          :options="positionOptions"
          @change="handleChange">
            <template slot-scope="{ node, data }">
              <span>{{ data.label }}</span>
              <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
            </template>
          </el-cascader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="bindSubmit" class="btn">我来应聘了</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { setJob } from '@/request/api'
import { get } from '@/request/http'
export default {
  name: 'JobInfo',
  data () {
    return {
      form: {
        name: '',
        phone: '',
        position: ''
      },
      positionOptions: [{
        value: '设计类',
        label: '设计类',
        children: [{
          value: '平面设计',
          label: '平面设计'
        }]
      }, {
        value: '商务媒介类',
        label: '商务媒介类',
        children: [{
          value: '媒介',
          label: '媒介'
        }, {
          value: '商务经理',
          label: '商务经理'
        }, {
          value: '招商助理',
          label: '招商助理'
        }]
      }, {
        value: '自媒体',
        label: '自媒体',
        children: [{
          value: '平面设计',
          label: '平面设计'
        }, {
          value: '微信视频号运营',
          label: '微信视频号运营'
        }]
      }, {
        value: '短视频',
        label: '短视频',
        children: [{
          value: '基础岗位',
          label: '基础岗位',
          children: [{
            value: '后期/摄像',
            label: '后期/摄像'
          }, {
            value: '策划',
            label: '策划'
          }]
        }, {
          value: '达人',
          label: '达人',
          children: [{
            value: '美妆达人（可实习）',
            label: '美妆达人（可实习）'
          }, {
            value: '短视频达人（可实习）',
            label: '短视频达人（可实习）'
          }]
        }, {
          value: '商业视频',
          label: '商业视频',
          children: [{
            value: '执行导演',
            label: '执行导演'
          }, {
            value: '摄像师',
            label: '摄像师'
          }, {
            value: '后期剪辑师',
            label: '后期剪辑师'
          }, {
            value: '文案策划',
            label: '文案策划'
          }]
        }]
      }, {
        value: '游戏',
        label: '游戏',
        children: [{
          value: '游戏文案（可实习）',
          label: '游戏文案（可实习）'
        }, {
          value: '游戏后期剪辑（可实习）',
          label: '游戏后期剪辑（可实习）'
        }, {
          value: '游戏体验师（可实习）',
          label: '游戏体验师（可实习）'
        }]
      }]
    }
  },
  methods: {
    handleChange () {
      console.log('..')
    },
    bindSubmit () {
      get(setJob + `/name/${this.form.name}/phone/${this.form.phone}/job/${this.form.position}`).then(res => {
        console.log(res)
        if (res.retCode === 200) {
          this.$message.success('提交成功')
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
.el-cascader
  display: block
.el-form >>> .el-form-item__content
  text-align: center
.el-button
  display: block
  width: 100%
  background: #FF6D6D
  border: none
  margin-top: 120px
</style>
