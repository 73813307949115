const axios = require('axios')

module.exports = {
  get (url, params) {
    return new Promise((resolve, reject) => {
      axios.get(url, { params }).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  post (url, data) {
    return new Promise((resolve, reject) => {
      axios.post(url, { data }).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  },
  /**
   * 封装post请求
   * @param {url} url 请求地址
   * @param {params} params jsondata参数
   */
  fetchPost (url, mydata) {
    return new Promise((resolve, reject) => {
      const params = new FormData()
      params.append('jsonData', JSON.stringify(mydata))
      axios.post(url, params).then(res => {
        resolve(res.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
}
