<template>
  <div>
    <el-image :src="mainImg" class="mainImg"></el-image>
  </div>
</template>

<script>
import { get } from '@/request/http'
import { getCompServices } from '@/request/api'
export default {
  name: 'Services',
  data () {
    return {
      mainImg: ''
    }
  },
  mounted () {
    this.getCompService()
  },
  methods: {
    getCompService () {
      get(getCompServices).then(res => {
        if (res.retCode === 200) {
          this.mainImg = res.param[0].imageUrl
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
.mainImg
  width: 100%
  margin-top: 62px
</style>
