import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'reset-css'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import VueClipboard from 'vue-clipboard2'
import moment from 'moment'

VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.prototype.$http = axios
Vue.use(ElementUI)
Vue.use(VueCookies)
moment.locale('zh-cn')
Vue.prototype.$moment = moment

const Bus = new Vue()

new Vue({
  router,
  data () {
    return {
      Bus
    }
  },
  render: h => h(App)
}).$mount('#app')
