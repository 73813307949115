<template>
  <div>
    <el-image class="profile-main" :src="cData"></el-image>
  </div>
</template>

<script>
export default {
  name: 'CompanyProfile',
  data () {
    return {
    }
  },
  props: {
    cData: String
  }
}
</script>

<style scoped lang="stylus">
.profile-main
  width: 100%
  display: block
</style>
